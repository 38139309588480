/* eslint-disable import/no-unresolved */
/* eslint-disable import/extensions */
/* eslint-disable import/no-extraneous-dependencies */
import React, { useState } from 'react'
import styled from 'styled-components'
import { Link, useStaticQuery, graphql } from 'gatsby'
import { CSSTransition } from 'react-transition-group'

// Components
import CustomLink from 'components/shared/CustomLink'
import Socials from 'components/Socials'

// Elements
import ButtonDefault from 'components/elements/ButtonDefault'

// Images
import imgLogo from 'img/logo.svg'

// Icons
import chevronDown from 'img/chevron-double-down-menu.svg'

const StyledHeader = styled.nav`
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  z-index: 10;
`

const brandWidth = 175
const brandWidthResponsive = 100

const Top = styled.div`
  padding-left: ${brandWidth}px;
`

const Bottom = styled.div`
  background-color: ${props => props.theme.color.light};
  min-height: 60px;
`

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 60px;

  @media (min-width: 350px) {
    padding-left: ${props => props.headerTop ? 150 : 0}px;
  }

  @media (min-width: 992px) {
    padding-left: ${props => props.headerTop ? 200 : 250}px;
  }

  @media (min-width: 1200px) {
    padding-left: ${props => props.headerTop ? 200 : 450}px;
  }
`

const Brand = styled(CustomLink)`
  background-color: ${props => props.theme.color.dark};
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  font-size: 0;

  & > img {
    position: absolute;
    z-index: 2;
    left: 50%;
  }

  @media screen and (min-width: ${props => props.headerTop ? '5000px' : '992px'}) {
    width: ${brandWidth}px;
    height: ${props => props.headerTop ? 125 : 175}px;

    & > img {
      height: ${brandWidth - 20}px;
      width: ${brandWidth - 20}px;
      margin-left: -${parseFloat((brandWidth - 20) / 2)}px;
      bottom: -35px;
    }
  }

  @media screen and (max-width: ${props => props.headerTop ? '5000px' : '991px'}) {
    width: ${brandWidthResponsive}px;
    height: 100px;
    margin-left: 15px;

    & > img {
      height: ${brandWidthResponsive - 20}px;
      width: ${brandWidthResponsive - 20}px;
      margin-left: -${parseFloat((brandWidthResponsive - 20) / 2)}px;
      bottom: -20px;
    }
  }
`

const Hexagon = styled.div`
  background-color: ${props => props.theme.color.dark};
  position: absolute;
  bottom: -10px;
  left: 0;
  z-index: 1;
  border-radius: 10px;
  transition: opacity 0.5s;

  &:before,
  &:after {
    position: absolute;
    width: inherit;
    height: inherit;
    border-radius: inherit;
    background: inherit;
    content: '';
  }

  &:before {
    transform: rotate(60deg);
  }

  &:after {
    transform: rotate(-60deg);
  }

  @media screen and (min-width: ${props => props.headerTop ? '5000px' : '992px'}) {
    width: ${brandWidth}px;
    height: ${brandWidth / 1.6}px;
  }

  @media screen and (max-width: ${props => props.headerTop ? '5000px' : '991px'}) {
    width: ${brandWidthResponsive}px;
    height: ${brandWidthResponsive / 1.6}px;
  }
`

const NavButton = styled.button`
  border: 2px solid transparent;
  position: absolute;
  top: ${props => props.headerTop ? 10 : 60}px;
  right: 25px;
  width: 60px;
  height: 40px;
  padding: 0 10px;
  border-radius: 10px;
  z-index: 1;
  box-shadow: inset 0 0 3px rgba(0,0,0,0.5);
`

const NavButtonStripe = styled.div`
  background-color: ${props => props.theme.color.contrastDark};
  height: 2px;
  width: 100%;
  margin: 5px 0;
`

const Header = ({ headerTop, setFreezeLayout }) => {
  const {
    pages,
    allWordpressMenusMenusItems: { edges },
    contact: {
      acf: { phonenumber },
    },
  } = useStaticQuery(graphql`
    {
      pages: allWordpressPage {
        edges {
          node {
            wpId: wordpress_id
            title
            path
            parent: parent_element {
              wpId: wordpress_id
            }
          }
        }
      }
      allWordpressMenusMenusItems(filter: { wordpress_id: { eq: 2 } }) {
        edges {
          node {
            items {
              title
              url
              wpId: object_id
            }
          }
        }
      }
      contact: wordpressPage(wordpress_id: { eq: 13 }) {
        acf {
          phonenumber
        }
      }
    }
  `)

  const { items } = edges[0].node

  const [isCollapsed, setIsCollapsed] = useState(true)

  const collapse = () => {
    setFreezeLayout(isCollapsed)
    setIsCollapsed(!isCollapsed)
  }

  return (
    <StyledHeader>
      <div className="container position-relative">
        <Brand to="/" headerTop={headerTop}>
          <img src={imgLogo} alt="" />
          <Hexagon headerTop={headerTop} />
          Home
        </Brand>
        {!headerTop && (
          <Top className="py-3">
            <Socials />
          </Top>
        )}
      </div>
      <Bottom>
        <Container className="container" headerTop={headerTop}>
          {headerTop && (
            <Socials />
          )}

          <NavButton
            type="button"
            onClick={collapse}
            className="d-block d-lg-none"
            headerTop={headerTop}
          >
            <NavButtonStripe />
            <NavButtonStripe />
            <NavButtonStripe />
          </NavButton>

          <HeaderMenuMobile
            items={items}
            isCollapsed={isCollapsed}
            collapse={collapse}
            phonenumber={phonenumber}
            pages={pages}
          />
          <HeaderMenuDesktop items={items} pages={pages} phonenumber={phonenumber} />
        </Container>
      </Bottom>
    </StyledHeader>
  )
}

const StyledTransition = styled(CSSTransition)`
  &-enter {
    opacity: 0;
  }

  &-enter-active {
    opacity: 1 !important;
    transition: opacity 300ms, transform 300ms;
  }

  &-exit {
    opacity: 1;
  }

  &-exit-active {
    opacity: 0;
    transition: opacity 300ms, transform 300ms;
  }
`

const StyledHeaderMenuMobile = styled.div`
  background-color: ${props => props.theme.color.light};
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 99;

  & ul {
    margin: 0;
    padding: 0;
    text-align: center;
    overflow-y: scroll;
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    max-width: 350px;
    margin: 0 auto;

    & a {
      font-size: ${props => props.theme.font.size.xl};
    }
  }
`

const NavButtonClose = styled.div`
  position: relative;
  width: 32px;
  height: 32px;

  &:before,
  &:after {
    background-color: ${props => props.theme.color.contrastDark};
    position: absolute;
    content: ' ';
    height: 33px;
    width: 2px;
  }

  &:before {
    transform: rotate(45deg);
  }

  &:after {
    transform: rotate(-45deg);
  }
`

const HeaderMenuMobile = ({
  pages,
  items,
  isCollapsed,
  collapse,
  phonenumber,
}) => (
  <StyledTransition in={!isCollapsed} timeout={300} unmountOnExit>
    <StyledHeaderMenuMobile>
      <NavButton type="button" onClick={collapse}>
        <NavButtonClose />
      </NavButton>

      <ul>
        <HeaderMenuItems items={items} pages={pages} phonenumber={phonenumber} />
      </ul>
    </StyledHeaderMenuMobile>
  </StyledTransition>
)

const StyledHeaderMenuDesktop = styled.ul`
  flex: 1;
  align-items: center;
  justify-content: space-around;
  list-style: none;
  margin-bottom: 0;
`

const HeaderMenuDesktop = ({ items, phonenumber, pages }) => (
  <StyledHeaderMenuDesktop className="d-none d-lg-flex">
    <HeaderMenuItems items={items} pages={pages} phonenumber={phonenumber} />
  </StyledHeaderMenuDesktop>
)

const Submenu = styled.div`
  background-color: ${props => props.theme.color.light};
  
  @media (min-width: 992px) {
    display: none;
    position: absolute;
    top: 100%;
    left: 0;
    width: 200px;
  }

  @media (max-width: 991px) {
    & > a > div {
      font-size: ${props => props.theme.font.size.xm};
    }
  }
`

const Item = styled.li`
  position: relative;
  padding: 15px 20px;

  & > a {
    font-family: ${props => props.theme.font.family.secondary};
    font-size: ${props => props.theme.font.size.xm};
    font-weight: ${props => props.theme.font.weight.s};
    letter-spacing: 1px;
    color: ${props => props.theme.color.text.secondary};
    text-transform: uppercase;

    &[aria-current] {
      color: ${props => props.theme.color.text.contrast};
    }

    &:hover {
      color: ${props => props.theme.color.text.contrast};
      text-decoration: underline;
    }
  }

  & > .phone {
    position: absolute;
    top: 75%;
    left: 50%;
    display: block;
    width: 200px;
    margin-left: -100px;
    margin-top: 5px;
  }

  &:hover > ${Submenu} {
    display: block;
  }
`

const SubmenuItem = styled(CustomLink)`
  color: ${props => props.theme.color.text.secondary};
  font-weight: ${props => props.theme.font.weight.m};
  display: block;
  padding: 5px 20px;

  &[aria-current="page"] {
    font-weight: ${props => props.theme.font.weight.l};
  }

  &:hover {
    color: ${props => props.theme.color.text.contrast};
    text-decoration: underline;
  }
`

const Icon = styled.img`
  width: 10px;
  height: 10px;
  margin-left: 10px;
  position: relative;
  bottom: 2px;
`

const HeaderMenuItems = ({ items, pages, phonenumber }) => {
  return (
    <>
      <Item>
        <Link to="/">Home</Link>
      </Item>
      {items.map(({ url, title, wpId }) => {
        let subPages = []

        if(pages) {
          subPages = pages.edges.filter(({ node }) => node.parent && node.parent.wpId !== 2 && parseFloat(node.parent.wpId) === parseFloat(wpId))
        }

        const hasSubPages = subPages && subPages.length > 0

        return (
          <Item key={wpId}>
            <CustomLink to={url}>
              {title}
              {hasSubPages && (
                <Icon src={chevronDown} alt="" />
              )}
            </CustomLink>
            {hasSubPages && (
              <Submenu className="py-lg-2">
                {subPages.map(({ node }) => (
                  <SubmenuItem key={node.wpId} to={node.path}>
                    <div dangerouslySetInnerHTML={{ __html: node.title }} />
                  </SubmenuItem>
                ))}
              </Submenu>
            )}
            {parseFloat(wpId) === 13 && (
              <ButtonDefault
                className="phone d-none d-lg-block"
                to={`tel:${phonenumber}`}
                isAnchor
                phone
              >
                {phonenumber}
              </ButtonDefault>
            )}
          </Item>
        )
      })}
    </>
  )
}

export default Header
