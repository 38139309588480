/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
import React, { useState } from 'react'
import styled from 'styled-components'
import CookieConsent from 'react-cookie-consent'

// Theme
import Theme from 'styles/Theme'

// Components
import Footer from './Footer'
import Header from './Header'

// CSS
import 'styles/all.css'

const StyledLayout = styled.div`
  padding-top: ${props => props.headerTop ? 60 : 0}px;
  overflow-y: ${props => props.freezeLayout ? 'hidden' : 'auto'};
  position: ${props => props.freezeLayout ? 'absolute' : 'relative'};
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
`

const Content = styled.div`
  
`

const Layout = ({ children, headerTop }) => {
  const [freezeLayout, setFreezeLayout] = useState(false)

  return (
    <>
      <Theme>
        <StyledLayout headerTop={headerTop} freezeLayout={freezeLayout}>
          <Header headerTop={headerTop} setFreezeLayout={setFreezeLayout} />
          <Content>{children}</Content>
          <Footer />
        </StyledLayout>
      </Theme>
      <CookieConsent
        buttonClasses="button-cookies"
        location="bottom"
        style={{ backgroundColor: '#CF8432', color: '#FFFFFF' }}
        buttonText="Accepteren"
      >
        <div className="px-lg-5">
          <div className="px-lg-5 text-center">
            <div>We gebruiken cookies om ervoor te zorgen dat onze website zo soepel mogelijk draait. Als u doorgaat met het gebruiken van de website, gaan we er vanuit dat u ermee instemt.</div>
          </div>
        </div>
      </CookieConsent>
    </>
  )
}

export default Layout
