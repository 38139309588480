/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
import React from 'react'
import styled, { css } from 'styled-components'
import chevronDoubleRight from 'img/chevron-double-right.svg'
import phoneCall from 'img/phone-call.svg'
import whatsapp from 'img/whatsapp.svg'
import ButtonShell from './ButtonShell'

export const StyledButtonCSS = css`
  display: inline-block;
  
  & > a, & > button {
    font-size: ${props => props.theme.font.size.m};
    font-weight: ${props => props.theme.font.weight.m};
    font-family: ${props => props.theme.font.family.main};
    background-color: ${props => props.theme.color.contrast};
    color: ${props => props.theme.color.text.light};
    padding-left: 5px;
    padding-right: 20px;
    height: 45px;
    line-height: 45px;
    width: 100%;
    border-radius: 25px;
    display: flex;
    transition: all .3s;

    &:focus {
      outline: none;  
    }

    &:hover {
      transform: scale(1.05);
      text-decoration: underline;
    }
  }
`

const StyledButton = styled.span`
  ${StyledButtonCSS};
`

export const IconCSS = css`
  background-color: ${props => props.theme.color.contrastDark};
  height: 35px;
  width: 35px;
  border-radius: 50%;
  margin-top: 5px;
  margin-right: 10px;
  display: flex;
  justify-content: center;
  align-items: center;

  & > img {
    height: 15px;
    width: 15px;
  }
`

const Icon = styled.span`
  ${IconCSS};
`

const ButtonDefault = ({ whatsApp, phone, isAnchor, isCustom, to, children, className }) => (
  <StyledButton className={className}>
    {isCustom ? children : (
      <ButtonShell to={to} isAnchor={isAnchor}>
        <Icon>
          {whatsApp && (<img src={whatsapp} alt="" />)}
          {phone && (<img src={phoneCall} alt="" />)}
          {!whatsApp && !phone && (<img src={chevronDoubleRight} alt="" />)}
        </Icon>
        {children}
      </ButtonShell>
    )}
  </StyledButton>
)

export default ButtonDefault