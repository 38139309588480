/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
import styled, { css } from 'styled-components'

export const StyledTitleDefaultCSS = css`
  font-size: ${props => props.theme.font.size.xxxl};
  font-family: ${props => props.theme.font.family.secondary};
  font-weight: ${props => props.theme.font.weight.sm};
  color: ${props => props.theme.color.text.light};
  text-shadow: 2px 2px 5px rgba(0, 0, 0, .3);
  letter-spacing: 2.5px; 

  & strong {
    font-weight: ${props => props.theme.font.weight.l};
  }
`

const TitleDefault = styled.h1`
  ${StyledTitleDefaultCSS};
`

export default TitleDefault