import React from 'react'
import { ThemeProvider, createGlobalStyle } from 'styled-components'

const mainTheme = {
  color: {
    main: '#1D3660',
    contrast: '#CF8432',
    contrastDark: '#9E5C14',
    secondary: '#E8E8E8',
    dark: '#1F2122',
    light: '#F2F0EC',

    text: {
      main: '#000000',
      secondary: '#212121',
      light: '#FFFFFF',
      contrast: '#CF8432',
    },
  },

  font: {
    family: {
      main: 'Maven Pro, Verdana',
      secondary: 'atrament-web, Arial',
      alternative: 'Athiti',
    },

    size: {
      xxs: '8px',
      xs: '10px',
      s: '12px',
      sm: '14px',
      m: '16px',
      xm: '20px',
      l: '24px',
      ml: '28px',
      xl: '32px', 
      xxl: '40px',
      xxxl: '50px',
    },

    weight: {
      xs: 200,
      s: 300,
      m: 400,
      xm: 500,
      l: 600,
      xl: 800,
    }
  }
}

const GlobalStyle = createGlobalStyle`
  body {
    font-family: ${props => props.theme.font.family.main};
    font-size: ${props => props.theme.font.size.m};
    color: ${props => props.theme.color.text.main};
    background-color: ${props => props.theme.color.light};
  }
`

const Theme = ({ children }) => (
  <ThemeProvider theme={mainTheme}>
    <GlobalStyle />
    {children}
  </ThemeProvider>
)

export default Theme