/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
import React from 'react'
import styled from 'styled-components'
import chevronDoubleRight from 'img/chevron-double-right.svg'
import phoneCall from 'img/phone-call.svg'
import whatsapp from 'img/whatsapp.svg'
import ButtonShell from './ButtonShell'

import { StyledButtonCSS, IconCSS } from './ButtonDefault'

const StyledButton = styled.span`
  ${StyledButtonCSS};

  & > a, & > button {
    border: 1px solid ${props => props.theme.color.text.light};
    background-color: transparent;
  }
`

const Icon = styled.span`
  ${IconCSS};

  && {
    margin-top: 4px;
  }
`

const ButtonAlt = ({ whatsApp, phone, isAnchor, isCustom, to, children, className }) => (
  <StyledButton className={className}>
    {isCustom ? children : (
      <ButtonShell to={to} isAnchor={isAnchor}>
        <Icon>
          {whatsApp && (<img src={whatsapp} alt="" />)}
          {phone && (<img src={phoneCall} alt="" />)}
          {!whatsApp && !phone && (<img src={chevronDoubleRight} alt="" />)}
        </Icon>
        {children}
      </ButtonShell>
    )}
  </StyledButton>
)

export default ButtonAlt