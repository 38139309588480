/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import styled from 'styled-components'

const StyledSocials = styled.div`
  display: flex;
`

const Social = styled.a`
  background-color: ${props => props.theme.color.contrast};
  font-size: 0;
  position: relative;
  width: 30px; 
  height: 17.32px;
  margin: 0 5px;
  display: flex;
  justify-content: center;
  align-items: center;

  &:before, &:after {
    content: '';
    position: absolute;
    left: 0;
    width: 0;
    border-left: 15px solid transparent;
    border-right: 15px solid transparent;
  }

  &:before {
    bottom: 100%;
    border-bottom: 8.66px solid ${props => props.theme.color.contrast};
  }

  &:after {
    top: 100%;
    width: 0;
    border-top: 8.66px solid ${props => props.theme.color.contrast};
  }

  & > img {
    width: 13px;
    height: 13px;
  }

  &:hover {
    background-color: ${props => props.theme.color.dark};

    &:before {
      border-bottom: 8.66px solid ${props => props.theme.color.dark};
    }

    &:after {
      border-top: 8.66px solid ${props => props.theme.color.dark};
    }
  }
`

const Socials = ({ className }) => {
  const {
    allWordpressWpSocials: { edges: items }
  } = useStaticQuery(graphql`
    {
      allWordpressWpSocials {
        edges {
          node {
            wpId: wordpress_id
            title
            acf {
              url
              icon {
                localFile {
                  publicURL
                }
              }
            }
          }
        }
      }
    }
  `)

  return (
    <StyledSocials className={className}>
      {items.map(({ node: { wpId, title, acf: { url, icon } } }) => (
        <Social key={wpId} href={url} target="_blank" rel="noopener noreferrer">
          <img src={icon.localFile.publicURL} alt={title} />
          {title}
        </Social>
      ))}
    </StyledSocials>
  )
}

export default Socials