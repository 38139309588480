/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
/* eslint-disable import/no-extraneous-dependencies */
import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import styled, { css } from 'styled-components'

// Components
import CustomLink from 'components/shared/CustomLink'
import Socials from 'components/Socials'

// Elements
import ButtonAlt from 'components/elements/ButtonAlt'

// Images
import imgLogo from 'img/logo.svg'

const StyledFooter = styled.div`
  background-color: #3B3B3B;
  position: relative;
  z-index: 1;
`

const Title = styled.h4`
  font-family: ${props => props.theme.font.family.secondary};
  font-size: ${props => props.theme.font.size.xm};
  color: ${props => props.theme.color.text.light};
  text-transform: uppercase;
  letter-spacing: 1px;
`

const Label = css`
  display: block;
  font-size: ${props => props.theme.font.size.sm};
  color: ${props => props.theme.color.text.light};
  margin-bottom: 5px;
`

const Link = styled(CustomLink)`
  ${Label};

  &:hover {
    color: ${props => props.theme.color.text.contrast};
    text-decoration: underline;
  }
`

const Anchor = styled.a`
  ${Label};

  &:hover {
    color: ${props => props.theme.color.text.light};
    text-decoration: underline;
  }
`

const Value = styled.div`
  ${Label};
`

const Contact = styled.div`
  background-color: ${props => props.theme.color.contrast};
  
  @media (min-width: 992px) {
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
  }

  @media (max-width: 991px) {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }
`

const Brand = styled(CustomLink)`
  background-color: ${props => props.theme.color.dark};
  position: relative;
  top: -25px;
  display: inline-block;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  font-size: 0;

  &:after {
    position: absolute;
    right: -25px;
    top: 0%;
    content: '';
    width: 0; 
    height: 0; 
    border-left: 25px solid transparent;
    border-right: 25px solid transparent;
    border-bottom: 25px solid #4E4E4E;
    z-index: -1;
  }
`

const Footer = () => {
  const {
    site,
    arrangements,
    halls,
    contact,
    menu,
    privacyPage
  } = useStaticQuery(graphql`
    {
      site {
        siteMetadata {
          title
        }
      }
      arrangements: allWordpressPage(filter: {parent_element: {wordpress_id: {eq: 7}}}) {
        edges {
          node {
            path
            title
            wpId: wordpress_id
          }
        }
      }
      halls: allWordpressPage(filter: {parent_element: {wordpress_id: {eq: 9}}}) {
        edges {
          node {
            path
            title
            wpId: wordpress_id
          }
        }
      }
      contact: wordpressPage(wordpress_id: {eq: 13}) {
        acf {
          streetname
          housenumber
          zipcode
          city
          phonenumber
          emailAddress: email_address
          whatsAppNumber: whatsapp_number
        }
      }
      menu: wordpressMenusMenusItems(wordpress_id: {eq: 3}) {
        items {
          wpId: wordpress_id
          url
          title
        }
      }
      privacyPage: wordpressPage(wordpress_id: {eq: 3}) {
        title
        path
      }
    }
  `)

  return (
    <StyledFooter>
      <div className="container">
        <div className="row">
          <div className="col-lg-3">
            <Brand className="pt-5 px-3 pb-3" to="/">
              <img src={imgLogo} alt="" />
              Home
            </Brand>
            <div className="mt-4">
              <Socials />
            </div>
            <div className="mt-5">
              <Link to={privacyPage.path}>
                {privacyPage.title}
              </Link>
            </div>
          </div>
          <div className="col-lg-3 mt-4 mt-lg-0 py-lg-5">
            <div>
              <Title>
                Arrangementen
              </Title>
              <div className="mt-3">
                {arrangements.edges.map(({ node: { wpId, path, title } }) => (
                  <Link key={wpId} to={path}>
                    <div dangerouslySetInnerHTML={{ __html: title }} />
                  </Link>
                ))}
              </div>
            </div>
            <div className="mt-4">
              <Title>
                Zalen
              </Title>
              <div className="mt-3">
                {halls.edges.map(({ node: { wpId, path, title } }) => (
                  <Link key={wpId} to={path}>
                    <div dangerouslySetInnerHTML={{ __html: title }} />
                  </Link>
                ))}
              </div>
            </div>
          </div>
          <div className="col-lg-3 mt-4 mt-lg-0 py-lg-5">
            <div>
              <Title>
                Adres
              </Title>
              <div className="mt-3">
                <Value>{site.siteMetadata.title}</Value>
                <Value>
                  {contact.acf.streetname} 
                  {' '}
                  {contact.acf.housenumber}
                </Value>
                <Value>{contact.acf.zipcode}</Value>
                <Value>{contact.acf.city}</Value>
              </div>
            </div>
            <div className="mt-4">
              <Title>
                {site.siteMetadata.title}
              </Title>
              <div className="mt-3">
                {menu.items.map(({ wpId, url, title }) => (
                  <Link key={wpId} to={url}>
                    <div dangerouslySetInnerHTML={{ __html: title }} />
                  </Link>
                ))}
              </div>
            </div>
          </div>
          <div className="col-lg-3 mt-4 mt-lg-0">
            <Contact className="px-3 py-5">
              <Title light>
                Contact
              </Title>
              <div className="mt-3">
                <Anchor href={`mailto:${contact.acf.emailAddress}`}>
                  {contact.acf.emailAddress}
                </Anchor>
                <Anchor href={`tel:${contact.acf.phonenumber}`}>
                  {contact.acf.phonenumber}
                </Anchor>
              </div>
              <div className="mt-4">
                <ButtonAlt
                  whatsApp
                  to={`whatsapp://send?abid=${contact.acf.whatsAppNumber}&text=Hoi, ik heb een vraag.`}
                  isAnchor
                >
                  WhatsApp ons
                </ButtonAlt>
              </div>
            </Contact>
          </div>
        </div>
      </div>
    </StyledFooter>
  )
}

export default Footer
